import React from "react"
import Favicon from 'react-favicon';
import { useStaticQuery } from "gatsby";
import styles from './index.module.less';

const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query HomeData {
            markdownRemark(frontmatter: {path: { eq: "/"} }) {
                frontmatter {
                    name {
                        first_name
                        middle_initial
                        last_name
                    }
                    maintenance_mode
                    services
                    mission_goal
                    more_info
                    about_me
                    contact_info {
                        email
                        phone
                    }
                    badge
                    logo
                }
            }  
        }
    `);
    const { markdownRemark } = data;
    const { frontmatter } = markdownRemark;
    const { name, services, maintenance_mode, mission_goal, more_info, about_me, contact_info, badge, logo } = frontmatter;
    const { first_name, middle_initial, last_name } = name;
    const { email, phone } = contact_info;
    console.log(logo);
    return (
        <>
            <Favicon url={logo} />
            <div className={styles.Wrapper}>
                <div className={styles.Content}>
                    <div className={styles.Header}>
                        <div className={styles.Name}> <img src={logo} /> {first_name} {middle_initial} {last_name}</div>
                        <div className={styles.Tagline}>{services}</div>
                    </div>
                    <div className={[styles.Body, styles.First].join(` `)}>
                        <div className={styles.Left}>
                            {about_me}
                        </div>
                    </div>
                    <div className={[styles.Body, styles.Second].join(` `)}>
                        <div className={styles.Contact}>
                            <a href={`mailto:${email}`}>{email}</a>
                            <img src={badge} className={styles.Badge}/>
                        </div>
                        <div className={styles.Right}>
                            {mission_goal}
                        </div>
                    </div>
                    <div className={styles.Box}>
                        <div>{more_info}</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IndexPage
